.App {
  text-align: center;
  /* background-image: url("bg-3.png"); */
  animation: fadeIn 1s ease-in;
}

* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loader {
  animation: fadeOut 1s ease-out;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.glass {
  /* Glass */

  box-sizing: border-box;

  background: rgba(5, 16, 29, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(7.8px);
  /* border-radius: 2rem; */
}

.glass2 {
  /* Glass */

  box-sizing: border-box;

  background: rgba(5, 16, 29, 0.8);
  border: 0.5px solid #ffffff;
  backdrop-filter: blur(7.8px);
}
